import { Contests } from 'services/contests';
import { Notifier } from 'common/ui';

export class List {
    static inject = [Contests, Notifier];
    _contests;
    _notifier;

    contests = [];

	constructor(contests, notifier) {
	    this._contests = contests;
        this._notifier = notifier;
	}

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.contests = await this._contests.all();
        } catch (err) {
            console.log(err);
        }
	}

    async awardPoints(contest) {
        try {
            const result = await this._contests.awardPoints(contest.code, contest.awardMember.id, contest.awardPointCode);
            if (result.succeeded) {
                this._notifier.success('contest-points-awarded');
                contest.awardMember = undefined;
            } else {
                this._notifier.error(result.message);
            }
        } catch (err) {
            console.log(err);
        }
    }
}
