import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';

export class EnterTargetPremium {
    static inject = [DialogController, NewInstance.of(ValidationController)];
    dialogController;
    _vc;

    targetPremium;

    constructor(dialogController, validationController) {
        this.dialogController = dialogController;
        this._vc = validationController;
        this._vc.addRenderer(new BootstrapFormValidationRenderer());

        ValidationRules
            .ensure('targetPremium').required().satisfiesRule('decimalRange', 0, 9999999)
            .on(this);
    }

    activate(model) {
        this.carrierName = model.carrierName;
        this.policyNumber = model.policyNumber;
        this.targetPremium = undefined;
        this._vc.reset();
    }
    
    async save() {
        const v = await this._vc.validate();
        if (!v.valid) return;
        this.dialogController.ok({ targetPremium: this.targetPremium });
    }
} 

 