import { PLATFORM } from 'aurelia-pal';
import { I18n } from 'common/i18n';
import { DialogService } from 'aurelia-dialog';
import { Content } from 'services/content';
import { Notifier } from 'common/ui';
import { SetEmailParameters } from './dialog/set-email-parameters';
PLATFORM.moduleName('./dialog/set-email-parameters');

export class Emails {
    static inject = [I18n, DialogService, Content, Notifier];
    _i18n;
    _dialogService;
    _content;
    _notifier;

    title = '';
    emails = [];
    editEmail = null;
    emailFilters = [{ value: '', keys: ['title'] }];

    constructor(i18n, dialogService, content, notifier) {
        this._i18n = i18n;
        this._dialogService = dialogService;
        this._content = content;
        this._notifier = notifier;
    }

    async attached() {
        await this._load();
        this.type = 'Email';
    }

    async display(email) {
        try {
            this.editEmail = email;
            this.focusFilter = false;
            this.emailParameters = await this._content.emailSettings(email.key);
        } catch (err) {
            console.log(err);
        }
    }

    async _load() {
        try {
            this.emails = [];
            const data = await this._content.emailKeys();
            data.forEach(d => this.emails.push({ key: d.key, type: 'Email', title: this._i18n.tr(`admin:${d.key}`) }));
            this.emails.sort((a, b) => a.title.localeCompare(b.title));
        } catch (err) {
            console.log(err);
        }
    }

    focusSearch() {
        this.focusFilter = true;
        return false;
    }

    async sendTestEmail() {
        try {
            this.sending = true;
            await this._content.sendTestEmail(this.editEmail.key);
            this._notifier.success('admin:test-email-sent');
        } catch (err) {
            console.log(err);
            this._notifier.error('admin:test-email-send-error');
        } finally {
            this.sending = false;
        }
    }

    async openEditor() {
        const model = {
            uiWidgetKey: this.editEmail.key,
            emailParameters: this.emailParameters,
        };
        await this._dialogService.open({ viewModel: SetEmailParameters, model: model, ignoreTransitions: true }).whenClosed(async(response) => {
            await this._load();
            const displayEmail = this.emails.find(x => x.key === this.editEmail.key);
            this.display(displayEmail);
        });
    }
}
