import { Carriers } from 'services/carriers';
import moment from 'moment';

export class FileImports {
    static inject = [Carriers]
    _carriers;

    pageSize = 25;
    imports = [];

    importFilters = [{ value: '', keys: ['importFileName', 'carrier', 'fileSource'] }];
    startDate = moment().add(-5, 'days');
    endDate = null;

    constructor(carriers) {
        this._carriers = carriers;
    }

    attached() {
        this._load();
    }

    refreshData() {
        this._load();
    }

    async _load() {
        try {
            this.loading = true;
            this.imports = await this._carriers.imports(this.startDate, this.endDate);
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    async downloadFile(i) {
        try {
            await this._carriers.downloadImportFile(i.importFileName);
        } catch (err) {
            console.log(err);
        }
    }
}
