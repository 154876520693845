import { DialogController } from 'aurelia-dialog';
import { Products } from 'services/products';

export class AssignProductAlias {
    static inject = [DialogController, Products];
    dialogController;
    _products;

    carrierId;
    carrierName;
    policyNumber;
    products;
    assignToProduct;

    constructor(dialogController, products) {
        this.dialogController = dialogController;
        this._products = products;
    }

    activate(model) {
        this.carrierId = model.carrier.id;
        this.carrierName = model.carrier.name;
        this.policyNumber = model.policyNumber;
        this.productName = model.productName;
        this.assignToProduct = undefined;
        this._load();
    }

    async _load() {
        try {
            this.products = await this._products.list(this.carrierId);
        } catch (err) {
            console.log(err);
        }
    }
    
    async save() {
        try {
            if (!this.assignToProduct) return;
            const result = await this._products.addAlias(this.assignToProduct.id, this.productName);
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
        }
    }
} 

 