import { PLATFORM } from 'aurelia-pal';
import { CarrierEditor } from './../../members/carriers/dialog/carrier-editor';
import { DialogService } from 'aurelia-dialog';
import { Carriers } from 'services/carriers';
import { Security } from 'common/security';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ROLE } from 'common/constants';
import { c } from 'common/common';
PLATFORM.moduleName('./../../members/carriers/dialog/carrier-editor');

export class CarrierList {
    static inject = [Carriers, DialogService, Security, EventAggregator]
    _carriers;
    _dialogService;
    _security;
    _ea;
    _handlers = [];

    CarrierAdminRole = ROLE.CarrierAdmin;

    loading = false;
    canEdit = false;

    carrierFilters = [{ value: '', keys: ['name', 'code'] }];

    carriers = [];

    constructor(carriers, dialogService, security, eventAggregator) {
        this._carriers = carriers;
        this._dialogService = dialogService;
        this._security = security;
        this._ea = eventAggregator;
    }

    attached() {
        this.canEdit = this._security.isInRole(ROLE.CarrierAdmin);
        this._load();
        this._handlers.push(this._ea.subscribe('dnd:didEnd', async() => {
            window.setTimeout(() => this._saveCarrierOrdering(), 0);
        }));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async _saveCarrierOrdering() {
        if (!this.canEdit) return;
        try {
            const orderedIds = this.dgCarriers.map(x => x.id);
            await this._carriers.setOrdering(orderedIds);
            this.triggerMenuUpdate();
        } catch (err) {
            console.log(err);
        }
    }

    triggerMenuUpdate() {
        this._carriers.clear();
        this._ea.publish(c.EventKeys.menu.reset, { menu: 'members', fireEvent: true });
    }

    async _load() {
        try {
            this.loading = true;
            this.carriers = await this._carriers.all();
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    async openEditor(carrier) {
        if (!this.canEdit) return;
        await this._dialogService.open({ viewModel: CarrierEditor, model: carrier, ignoreTransitions: true }).whenClosed(async(response) => {
            this.carriers = [];
            this.triggerMenuUpdate();
            await this._load();
        });
        return false;
    }

    async toggleCarrierActive(carrier) {
        try {
            if (!this.canEdit) return;
            carrier.isActive = !carrier.isActive;
            await this._carriers.setActive(carrier.id, carrier.isActive);
            this.triggerMenuUpdate();
        } catch (err) {
            console.log(err);
        }
    }
}
