import { Router } from 'aurelia-router';
import { Security } from 'common/security';
import { ROLE } from 'common/constants';

export class Dashboard {
    static inject = [Router, Security];
    _router;
    _security;

    constructor(router, security) {
        this._router = router;
        this._security = security;
    }

    attached() {
        try {
            const agentsRoute = this._router.routes.find(x => x.route === 'agents');
            if (!agentsRoute) return;
            if (this._security.isInRole(agentsRoute.settings.roles)) {
                this._router.navigate('#/admin/agents', true);
                return;
            }

            // Does not have access to manage agents, bring them to the correct page
            if (this._security.isInRole([ROLE.LeadManager])) {
                this._router.navigate('#/admin/leads', true);
                return;
            }
        } catch (err) {
            console.log(err);
        }
    }
}