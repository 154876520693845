/* eslint-disable require-atomic-updates */
import { observable, computedFrom } from 'aurelia-framework';
import { Api } from 'common/server';
import { Notifier } from 'common/ui';
import { I18n } from 'common/i18n';
import { Security } from 'common/security';
import { ROLE } from 'common/constants';
import moment from 'moment';

export class UiTerms {
    static inject = [Api, Notifier, I18n, Security];
    _api;
    _notifier;
    _i18n;
    security;

    view = 'untranslated';
    languages = [];
    selectedLanguage;
    atLeastOneTermChanged = false;

    @observable searchOn;

    constructor(api, notifier, i18n, security) {
        this._api = api;
        this._notifier = notifier;
        this._i18n = i18n;
        this.security = security;
    }

    async attached() {
        this.atLeastOneTermChanged = false;
        await this._load();
    }

    async detached() {
        if (!this.atLeastOneTermChanged) return;
        await this._i18n.reloadTranslations();
        this._notifier.success('You may need to refresh the browser to see the updated translations');
    }

    @computedFrom('selectedLanguage')
    get canEdit() {
        if (!this.selectedLanguage) return false;
        if (this.security.isInRole([ROLE.Admin, ROLE.WebsiteManager])) return true;
        if (this.security.isInRole(ROLE.Translator) && this.selectedLanguage.language !== 'en') return true;
        return false;
    }

    async _load() {
        try {
            this.languages = await this._api.get('content/ui-term');
            this.selectedLanguage = this.languages.find(x => x.isDefault);
        } catch (err) {
            this._notifier.error('An error occurred getting the terms');
        }
    }

    selectLanguage(language) {
        this.selectedLanguage = language;
    }

    isTermVisible(term) {
        if (this.view === 'all') return true;
        if (this.view === 'fuzzy' && term.isFuzzy) return true;
        if (this.view === 'untranslated' && term.isUntranslated) return true;
        return false;
    }

    searchOnChanged() {
        const searchOn = this.searchOn ? this.searchOn.toLowerCase() : undefined;
        this.selectedLanguage.terms.forEach(term => {
            let displayInList = false;
            if (searchOn) {
                if (term.code.toLowerCase().indexOf(searchOn) >= 0) displayInList = true;
                if (term && term.translation && term.translation.toLowerCase().indexOf(searchOn) >= 0) displayInList = true;
            }
            term.displayInList = displayInList;
        });
    }

    async onTranslationChanged(term) {
        try {
            term.isProcessing = true;
            this.atLeastOneTermChanged = true;
            await this._api.post('content/ui-term', term);
            this._notifier.success('Term updated');
            term.lastUpdated = moment().format();
            if (term.translation === '') {
                // removed translation
                term.isUntranslated = true;
                this.selectedLanguage.totalUntranslated += 1;
                this.selectedLanguage.totalTranslated -= 1;
                if (term.isFuzzy) this.selectedLanguage.totalFuzzy -= 1;
                term.isFuzzy = false;
                return;
            }
            // is translated
            if (term.isUntranslated) {
                this.selectedLanguage.totalTranslated += 1;
                this.selectedLanguage.totalUntranslated -= 1;
            }
            if (term.isFuzzy) this.selectedLanguage.totalFuzzy -= 1;
            term.isFuzzy = false;
            term.isUntranslated = false;
        } catch (err) {
            this._notifier.error('An error occurred saving the term');
        } finally {
            term.isProcessing = false;
        }
    }

    async notFuzzy(term) {
        try {
            term.isProcessing = true;
            await this._api.post('content/ui-term/not-fuzzy', term);
            this._notifier.success('Term marked as not fuzzy');
            term.isFuzzy = false;
            this.selectedLanguage.totalFuzzy -= 1;
        } catch (err) {
            this._notifier.error('An error occurred updating the term');
        } finally {
            term.isProcessing = false;
        }
    }

    showAddTerm(show) {
        this.showAdd = show;
        if (!this.showAdd) {
            this.newTranslation = '';
            this.newCode = '';
        }
    }

    async addTerm() {
        try {
            const exists = this.selectedLanguage.terms.find(x => x.code.toLowerCase() === this.newCode.toLowerCase());
            if (exists) {
                this._notifier.error('This code already exists');
                return;
            }
            const model = {
                code: this.newCode,
                translation: this.newTranslation,
            };
            await this._api.post('content/ui-term/add', model);
            await this._load();
            this.showAdd = false;
            this._notifier.success('Term added');
        } catch (err) {
            this._notifier.error('An error occurred adding the term');
        }
    }
}
