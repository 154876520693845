import { DialogController } from 'aurelia-dialog';

export class PolicyChangelog {
    static inject = [DialogController];
    dialogController;

    policy;

    constructor(dialogController) {
        this.dialogController = dialogController;
    }

    activate(model) {
        this.policy = model;
    }
} 

 