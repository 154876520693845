import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Security} from 'common/security';
import {Notifier, Page} from 'common/ui';
import {Api} from 'common/server';
import {I18n} from 'common/i18n';

@inject(EventAggregator, Security, Notifier, Page, Api, I18n)
export class PendingApprovals {

    approvals = [];
    approvalOfImgSrc = '';

    constructor(eventAggregator, security, notifier, page, api, i18n) {
        this.eventAggregator = eventAggregator;
        this.security = security;
        this.notifier = notifier;
        this.page = page;
        this.api = api;
        this.i18n = i18n;

        this._loadApprovals();
    }

    _loadApprovals() {
        var me = this;

        me.api.getData('approval/pending').then(data => {
            me.approvals = data;
        }).catch(error => {
            me.notifier.generalError();
        });
    }

    approve(approval) {
        var me = this;
        me.api.postData("approval/" + approval.id + "/approve", null).then(data => {
            me._loadApprovals();
            console.log(data);
        }).catch(error => {
            me.notifier.generalError();
        });
    }

    reject(approval) {
        var me = this;
        me.api.postData("approval/" + approval.id + "/reject", { comments: approval.comments }).then(data => {
            me._loadApprovals();
            console.log(data);
        }).catch(error => {
            me.notifier.generalError();
        });
    }

    toggleApproval(id) {
        for (var i = 0; i < this.approvals.length; i++) {
            if (this.approvals[i].id !== id) continue;
            this.approvals[i].show = !this.approvals[i].show;
            break;
        }
    }
}
