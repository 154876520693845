import { I18n } from 'common/i18n';
import { Content } from 'services/content';
import { c } from 'common/common';

export class ContentEditor {
    static inject = [I18n, Content];
    _i18n;
    _content;

    title = '';
    content = [];
    links = [];
    _agentSite = [];
    _profile = [];
    _site = [];
    _registration = [];
    _signIn = [];
    _integrations = [];
    _onboarding = [];
    _dashboardWidgets = [];
    editContent = null;
    canAdd = false;

    constructor(i18n, content) {
        this._i18n = i18n;
        this._content = content;
    }

    attached() {
        this._load();
        this.setContent('site');
    }

    display(content) {
        this.editContent = content;
    }

    setContent(key) {
        this.tab = key;
        this.links = [];
        this.canAdd = false;
        this.type = null;
        switch (key) {
            case 'site':
                this.content = this._site;
                this.title = 'Site';
                break;
            case 'agent-site': 
                this.content = this._agentSite; 
                this.title = 'Agent Site Shared'; 
                break;
            case 'profile': 
                this.content = this._profile; 
                this.title = 'Profile'; 
                break;
            case 'registration': 
                this.content = this._registration; 
                this.title = 'Registration'; 
                this.links.push({title:'Sign Up', href:'#/sign-up'});
                break;
            case 'sign-in': 
                this.content = this._signIn; 
                this.title = 'Sign In'; 
                this.links.push({title:'Sign In', href:'#/'});
                this.links.push({title:'Reset Password', href:'#/reset-password'});
                break;
            case 'integrations':
                this.content = this._integrations;
                this.title = 'Integrations';
                break;
            case 'onboarding':
                this.content = this._onboarding;
                this.title = 'Onboarding';
                break;
            case 'dashboard-widgets':
                this.content = this._dashboardWidgets;
                this.title = 'Dashboard Widget';
                this.canAdd = true;
                this.type = 'DashboardWidget';
                break;
            }

        this.display(this.content[0]);
    }

    async _load() {
        this._site.push({ key: 'placement-percentage-explanation', title: 'Placement Percentage Explanation', type: null });

        this._agentSite.push({ key: 'agent-site-bio-default', title: 'Default Bio', type: 'AgentSite'});
        this._agentSite.push({ key: 'agent-site-about-us-company-overview', title: 'Company Overview', type: 'AgentSite' });
        this._agentSite.push({ key: 'agent-site-about-us-agent-overview', title: 'Agent Overview', type: 'AgentSite' });
        this._agentSite.push({ key: 'agent-site-about-us-why-choose', title: 'Why Choose LPFN', type: 'AgentSite' });

        this._profile.push({key: 'profile-validation-error', title: 'Profile - There is a validation error', type: null});
        this._profile.push({key: 'member-site-bio-editor', title: 'Member Site - Bio Editor instructions', type: null});
        this._profile.push({key: 'member-site-bio-has-pending-bio-approvals', title: 'Member Site - Bio Editor - there are pending bio approvals', type: null});
        this._profile.push({key: 'member-site-bio-start', title: 'Member Site - Bio Editor - instructions the first time a bio is created', type: null});
        this._profile.push({key: 'member-site-bio-write-my-own-instructions', title: 'Member Site - Bio Editor - instructions for writing your own bio', type: null});
        this._profile.push({key: 'member-site-first-bio-saved', title: 'Member Site - Bio Editor - first time write your own bio is saved and sent for approval', type: null});
        this._profile.push({key: 'profile-member-can-have-site-instructions', title: 'Profile - Instructions for a team member who does not have a personal site, but can', type: null});
        this._profile.push({key: 'profile-member-has-site-instructions', title: 'Profile - Instructions for team member who has created a personal site', type: null});
        // this._profile.push({key: 'member-site-bio-step-1-title', title: 'Member Site - Bio - Wizard - Question 1', type: null});
        // this._profile.push({key: 'member-site-bio-step-1-example', title: 'Member Site - Bio - Wizard - Question 1 Example', type: null});
        // this._profile.push({key: 'member-site-bio-step-2-title', title: 'Member Site - Bio - Wizard - Question 2', type: null});
        // this._profile.push({key: 'member-site-bio-step-2-example', title: 'Member Site - Bio - Wizard - Question 2 Example', type: null});
        // this._profile.push({key: 'member-site-bio-step-3-title', title: 'Member Site - Bio - Wizard - Question 3', type: null});
        // this._profile.push({key: 'member-site-bio-step-3-example', title: 'Member Site - Bio - Wizard - Question 3 Example', type: null});
        // this._profile.push({key: 'member-site-bio-step-4-title', title: 'Member Site - Bio - Wizard - Question 4', type: null});
        // this._profile.push({key: 'member-site-bio-step-4-example', title: 'Member Site - Bio - Wizard - Question 4 Example', type: null});
        // this._profile.push({key: 'member-site-bio-step-5-title', title: 'Member Site - Bio - Wizard - Question 5', type: null});
        // this._profile.push({key: 'member-site-bio-step-5-example', title: 'Member Site - Bio - Wizard - Question 5 Example', type: null});
        this._profile.push({key: 'national-producer-number-instructions', title: 'NPN Instructions', type: null});

        this._registration.push({key: 'sign-up-welcome', title: 'Registration - Welcome', type: null});
        this._registration.push({key: 'sign-up-welcome-has-direct-upline', title: 'Registration - Welcome - With Direct Upline', type: null});
        this._registration.push({key: 'sign-up-validation-errors', title: 'Registration - Validation Errors', type: null});
        this._registration.push({key: 'sign-up-sms-opt-in', title: 'Registration - SMS Opt-In', type: null});
        this._registration.push({key: 'sign-up-agreement-instructions', title: 'Registration - Writing Agent Agreement Instructions', type: null});
        this._registration.push({key: 'writing-agent-agreement', title: 'Writing Agent Agreement', type: null});
        this._registration.push({key: 'sign-up-agreement-agree', title: 'Registration - Agree to Terms Checkbox', type: null});
        this._registration.push({key: 'sign-up-agreement-view-terms', title: 'Registration - Writing Agent Agreement - View Terms', type: null});
        this._registration.push({key: 'sign-up-no-parent', title: 'Registration - Right box - No Direct Upline', type: null});
        this._registration.push({key: 'sign-up-set-direct-upline', title: 'Registration - Popup - No Direct Upline', type: null});
        this._registration.push({key: 'register-thank-you', title: 'Registration - Thank You', type: null});
        this._registration.push({key: 'register-thank-you-bottom', title: 'Registration - Thank You Bottom', type: null});
        this._registration.push({key: 'national-producer-number-instructions', title: 'NPN Instructions', type: null});

        this._signIn.push({key: 'reset-password', title: 'Reset Password - Instructions', type: null});
        this._signIn.push({key: 'reset-password-email-sent', title: 'Reset Password - Email has been sent message', type: null});
        this._signIn.push({key: 'reset-password-confirm', title: 'Reset Password - After clicking on link in email', type: null});
        this._signIn.push({key: 'reset-password-final', title: 'Reset Password - After resetting the password', type: null});
        this._signIn.push({key: 'login', title: 'Login - above form', type: null});
        this._signIn.push({key: 'login-invalid', title: 'Login is invalid', type: null});
        this._signIn.push({key: 'login-sidebar', title: 'Login - right sidebar', type: null});
        this._signIn.push({key: 'login-footer', title: 'Login - below login', type: null});

        this._integrations.push({key: 'surance-bay-sso-help', title: 'SuranceBay - waiting for SSO', type: null});

        this._onboarding.push({key: 'onboarding-welcome', title: 'Welcome', type: 'Onboarding'});
        this._onboarding.push({key: 'onboarding-add-profile-picture', title: 'Add Profile Picture (optional)', type: 'Onboarding'});
        this._onboarding.push({key: 'onboarding-have-license-life', title: 'LIFE: Have License?', type: 'Onboarding'});
        this._onboarding.push({key: 'onboarding-need-license-life', title: 'LIFE: Get Your License', type: 'Onboarding'});
        this._onboarding.push({key: 'onboarding-have-eo-life', title: 'LIFE: Have E&O?', type: 'Onboarding'});
        this._onboarding.push({key: 'onboarding-need-eo-life', title: 'LIFE: Get Your E&O', type: 'Onboarding'});
        this._onboarding.push({key: 'onboarding-upload-e-and-o-instructions', title: 'LIFE & ANNUITY: Upload E&O document', type: 'Onboarding'});
        this._onboarding.push({key: 'onboarding-enter-npn', title: 'LIFE & ANNUITY: Enter Your NPN', type: 'Onboarding'});
        this._onboarding.push({key: 'onboarding-access-surance-bay', title: 'LIFE & ANNUITY: Access SuranceBay to Get Contracted', type: 'Onboarding'});
        this._onboarding.push({key: 'onboarding-complete', title: 'Onboarding Complete', type: 'Onboarding'});

        try {
            const data = await this._content.allByType('DashboardWidget');
            data.forEach(d => {
                this._dashboardWidgets.push({ id: d.id, key: d.key, title: d.title || d.key, type: d.widgetType });
            });
        } catch (err) {
            console.log(err);
        }
    }

    addPage() {
        this.content.push({id: null, key: c.Helpers.uniqueId(), title: `New ${this.title}`, type: this.type});
        this.display(this.content[this.content.length - 1]);
    }

    async deletePage() {
        try {
            const idx = this.content.findIndex(x => x.key === this.editContent.key);
            if (idx < 0) return;
            if (this.editContent.id) {
                await this._content.delete(this.editContent.id);
            }
            this.content.splice(idx, 1);
            this.display(this.content[this.content.length - 1]);
        } catch (err) {
            console.log(err);
        }
    }
}
