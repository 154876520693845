import { PLATFORM } from 'aurelia-pal';
import { observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Api } from 'common/server';
import { Notifier, Page } from 'common/ui';
import { I18n } from 'common/i18n';
import { Carriers } from 'services/carriers';
import { DialogService } from 'aurelia-dialog';
import { EditCarrierPolicyStatus } from 'members/carriers/dialog/edit-carrier-policy-status';
import { EditProduct } from 'members/carriers/dialog/edit-product';
import { SelectPolicyStatus } from './dialog/select-policy-status';
import { EnterTargetPremium } from './dialog/enter-target-premium';
import { EnterSplit } from './dialog/enter-split';
import { EnterAgentNumber } from './dialog/enter-agent-number';
import { AssignProductAlias } from './dialog/assign-product-alias';
import { PolicyChangelog } from './dialog/policy-changelog';
import Dropzone from 'dropzone';
import moment from 'moment';
import environment from '../../../config/environment.json';
import { c } from 'common/common';
PLATFORM.moduleName('members/carriers/dialog/edit-carrier-policy-status');
PLATFORM.moduleName('members/carriers/dialog/edit-product');
PLATFORM.moduleName('./dialog/select-policy-status');
PLATFORM.moduleName('./dialog/enter-target-premium');
PLATFORM.moduleName('./dialog/enter-split');
PLATFORM.moduleName('./dialog/enter-agent-number');
PLATFORM.moduleName('./dialog/assign-product-alias');
PLATFORM.moduleName('./dialog/policy-changelog');

export class CarrierUploads {
    static inject = [Router, EventAggregator, Security, Api, Notifier, Page, I18n, Carriers, DialogService];
    _router;
    _ea;
    _security;
    _api;
    _notifier;
    _page;
    _i18n;
    _carriers;
    _dialogService;

    errorDescription;

    commissionFileDropzone;
    dropzoneCommissionFileEl;
    formActionCommissionFile;

    policyImportDate = moment();
    policyFileDropzone;
    dropzonePolicyFileEl;
    formActionPolicyFile;
    @observable ignoreWarnings = false;

    @observable carrierImportDate = moment();
    carrierFileDropzone;
    dropzoneCarrierFileEl;
    formActionCarrierFile;
    @observable ignoreCarrierWarnings = false;
    carrierImportAdditionalData = { statuses: [], targetPremiums: [], writingAgentChanges: [], ignores: [], splits: [] };
    carrierImportAdditionalDataValue;

    BOX_MESSAGE = 'Drag-n-drop or click to select';

    caPageSize = 10;
    errPageSize = 10;

    policyFilters = [
        { value: '', keys: ['c.code', 'policyNumber', 'writingAgentMember.fullName', 'agency.fullName', 'statusValue', 'insuredFullName'] },
        { value: 'nucs', custom: this.policyChangeFilter },
    ];
    changedPolicyFilters = [{ value: '', keys: ['c.code', 'policyNumber', 'writingAgentMember.fullName', 'agency.fullName', 'statusValue', 'insuredFullName']}];
    @observable policyStatusN = true;
    @observable policyStatusU = true;
    @observable policyStatusC = true;
    @observable policyStatusS = true;

    warningFilters = [
        { value: '', keys: ['codeValue', 'message', 'policyNumber'] },
        { value: '', custom: this.warningTypeFilter },
    ];

    errorFilters = [
        { value: '', keys: ['row', 'message', 'policyNumber'] },
    ];

    constructor(router, ea, security, api, notifier, page, i18n, carriers, dialogService) {
        this._router = router;
        this._ea = ea;
        this._security = security;
        this._api = api;
        this._notifier = notifier;
        this._page = page;
        this._i18n = i18n;
        this._carriers = carriers;
        this._dialogService = dialogService;

        this._setPolicyUploadFormAction();
        this._setCarrierUploadFormAction();
        this.formActionCommissionFile = `${environment.api}/api/carrier/import-commission-rates`;
	}

    attached() {
        this._initializePolicyFileDropzone();
        this._initializeCarrierFileDropzone();
        this._initializeCommissionFileDropzone();
    }

    async activate(model) {
        this.showUploadBoxes = true;
        this.showBackToImports = model.fileName ? true : false;
        this.showBackToTasks = model.exceptionId ? true : false;
        if (model.fileName || model.exceptionId) this.showUploadBoxes = false;
        this.exportFileName = model.fileName || undefined;
        if (model.fileName) {
            const response = { inQueue: true, importFileName: model.fileName };
            this._loadReimport(model.fileName);
            this._startCheckingQueue(response);
        } else if (model.exceptionId) {
            const response = await this._loadException(model.exceptionId);
            this._startCheckingQueue(response);
        } else {
            this.policyStatus = undefined;
            this.closeUploadSession();
        }
    }

    detached() {
        this._stopCheckingQueue();
    }

    async _loadException(id) {
        try {
            const importStatus = await this._carriers.importException(id);
            this.showReimportButton = true;
            return importStatus;
        } catch (err) {
            console.log(err);
        }
    }

    async _loadReimport(fileName) {
        try {
            const importStatus = await this._carriers.importStatus(fileName);
            this.showReimportButton = importStatus.canReimport;
        } catch (err) {
            console.log(err);
        }
    }

    async reprocessFile() {
        try {
            this.processing = true;
            this.policyStatus = undefined;
            this.errorDescription = undefined;
            this._stopCheckingQueue();
            const response = await this._carriers.reprocessImport(this.importFileName, this.carrierImportAdditionalData);
            this._startCheckingQueue(response);
        } catch (err) {
            console.log(err);
        }
    }

    policyStatusNChanged() {
        this._policyStatusChanged('n');
    }

    policyStatusUChanged() {
        this._policyStatusChanged('u');
    }

    policyStatusCChanged() {
        this._policyStatusChanged('c');
    }

    policyStatusSChanged() {
        this._policyStatusChanged('s');
    }

    _policyStatusChanged(policyChange) {
        if (this.policyFilters[1].value.indexOf(policyChange) >= 0) {
            this.policyFilters[1].value = this.policyFilters[1].value.replace(policyChange, '');
        } else {
            this.policyFilters[1].value += policyChange;
        }
    }

    policyChangeFilter(filterValue, row) {
        if (!filterValue) return false;
        if (filterValue.indexOf('n') >= 0 && row.isNewPolicy) return true;
        if (filterValue.indexOf('u') >= 0 && !row.isNewPolicy && row.isPolicyChanged) return true;
        if (filterValue.indexOf('c') >= 0 && !row.isPolicyChanged) return true;
        if (filterValue.indexOf('s') >= 0 && row.isSplit) return true;
        return false;
    }

    ignoreWarningsChanged() {
        this._setPolicyUploadFormAction();
    }

    warningTypeFilter(filterValue, row) {
        if (!filterValue) return true;
        return row.code === filterValue;
    }

    _setPolicyUploadFormAction() {
        this.formActionPolicyFile = `${environment.api}/api/production/import-policies?ignoreWarnings=${encodeURIComponent(this.ignoreWarnings)}`;
        if (this.policyFileDropzone) this.policyFileDropzone.options.url = this.formActionPolicyFile;
    }

	_initializePolicyFileDropzone() {
		const me = this;
		const d = new Date();
		const timezoneOffset = d.getTimezoneOffset();

		const myDropzone = new Dropzone(this.dropzonePolicyFileEl, {
			dictDefaultMessage: this.BOX_MESSAGE,
			paramName: 'file',
			maxFilesize: 10, // MB
			headers: { 'Authorization': 'Bearer ' + this._security.token, 'X-LEGACY-TimezoneOffset': timezoneOffset },
			acceptedFiles: '.xlsx,.xls,.csv',
			maxFiles: 1,
            timeout: 300000,
			init: function () {
				this.on('addedfile', function (file) {
                    me.processing = true;
                    me.policyStatus = undefined;
                    me.errorDescription = undefined;
					//console.log("added file.");
				});
				this.on('success', function (file, response) {
					this.removeFile(file);
                    me.processing = false;
                    me.policyStatus = response;
				});
				this.on('error', function (file, error, x) {
                    this.removeAllFiles();
					me._notifier.errorText('Error importing the policy file');
                    me.errorDescription = error.message;
                    me.processing = false;
				});
			}
		});

		this.policyFileDropzone = myDropzone;
	}

    carrierImportDateChanged() {
        this._setCarrierUploadFormAction();
    }

    ignoreCarrierWarningsChanged() {
        this._setCarrierUploadFormAction();
    }

    _setCarrierUploadFormAction() {
        this.formActionCarrierFile = `${environment.api}/api/carrier/import-file?ignoreWarnings=${encodeURIComponent(this.ignoreCarrierWarnings)}&asOfDate=${encodeURIComponent(this.carrierImportDate.format('l'))}&fileName=${this.importFileName ? encodeURIComponent(this.importFileName) : ''}`;
        if (this.carrierFileDropzone) this.carrierFileDropzone.options.url = this.formActionCarrierFile;
    }

	_initializeCarrierFileDropzone() {
		const me = this;
		const d = new Date();
		const timezoneOffset = d.getTimezoneOffset();

		const myDropzone = new Dropzone(this.dropzoneCarrierFileEl, {
			dictDefaultMessage: this.BOX_MESSAGE,
			paramName: 'file',
			maxFilesize: 10, // MB
			headers: { 'Authorization': 'Bearer ' + this._security.token, 'X-LEGACY-TimezoneOffset': timezoneOffset },
			acceptedFiles: '.xlsx,.xls,.csv,.txt',
			maxFiles: 1,
            timeout: 600000,
			init: function () {
				this.on('addedfile', function (file) {
                    me.processing = true;
                    me.policyStatus = undefined;
                    me.errorDescription = undefined;
                    me._stopCheckingQueue();
				});
				this.on('success', function (file, response) {
					this.removeFile(file);
                    me.processing = false;
                    me._startCheckingQueue(response);
				});
				this.on('error', function (file, error, x) {
                    this.removeAllFiles();
                    console.log(error);
					me._notifier.errorText('Error importing the policy file');
                    me.errorDescription = error.message;
                    me.processing = false;
				});
			}
		});

		this.carrierFileDropzone = myDropzone;
	}

	_initializeCommissionFileDropzone() {
		const me = this;
		const d = new Date();
		const timezoneOffset = d.getTimezoneOffset();

		const myDropzone = new Dropzone(this.dropzoneCommissionFileEl, {
			dictDefaultMessage: this.BOX_MESSAGE,
			paramName: 'file',
			maxFilesize: 6, // MB
			headers: { 'Authorization': 'Bearer ' + this._security.token, 'X-LEGACY-TimezoneOffset': timezoneOffset },
			acceptedFiles: '.xlsx,.xls',
			maxFiles: 1,
            timeout: 180000,
			init: function () {
				this.on('addedfile', function (file) {
                    me.processing = true;
                    me.policyStatus = undefined;
					//console.log("added file.");
				});
				this.on('success', function (file, response) {
					this.removeFile(file);
                    me.processing = false;
                    if (response && response.succeeded) {
    					me._notifier.successText('Commission rate file imported');
                    } else {
                        me._notifier.errorText(response.error);
                    }
				});
				this.on('error', function (file, error, x) {
                    this.removeAllFiles();
					me._notifier.errorText('Error importing the commission rate file.<br/>' + error.message);
                    me.processing = false;
				});
			}
		});

		this.commissionFileDropzone = myDropzone;
	}

    clearError() {
        this.errorDescription = undefined;
    }

    _startCheckingQueue(response) {
        try {
            this.checkingQueue = true;
            this.importFileName = response.importFileName;
            this._setCarrierUploadFormAction();
            if (!response.inQueue) {
                this._notifier.error('An error occurred processing the file.');
                return;
            }
            this._queueTimer = window.setInterval(this._checkQueueStatus, 2 * 1000);
        } catch (err) {
            console.log(err);
        }
    }

    _stopCheckingQueue() {
        this.checkingQueue = false;
        this.processingStatus = undefined;
        if (!this._queueTimer) return;
        clearInterval(this._queueTimer);
        this._queueTimer = null;
    }

    _checkQueueStatus = async () => {
        try {
            const status = await this._carriers.checkImportStatus(this.importFileName);
            this.showCancelButton = status.inQueue === true && !status.pickedFromQueueDate;
            if (status.inQueue === true) {
                this.processingStatus = status.processingStatus;
                return;
            }
            if (status.inQueue === false) {
                if (status.canceled === true) {
                    this._stopCheckingQueue();
                    return;
                }
                throw new Error('The file was processed but no status was found');
            }
            // Have the status, display it
            this._displayPolicyStatus(status);
        } catch (err) {
            console.log(err);
            this._notifier.error('Unable to get the file status');
            this._stopCheckingQueue();
        }
    }

    warningPolicy(warning) {
        return {
            id: warning.policyId,
            policyNumber: warning.policyNumber
        };
    }

    async cancelUploadSession() {
        await this.cancelFileImport();
        this.closeUploadSession();
        this.policyStatus = undefined;
        if (this.showBackToImports) {
            this._router.navigate('#/admin/carrier/imports', true);
        } else if (this.showBackToTasks) {
            this._router.navigate('#/members/tasks', true);
        }
    }

    closeUploadSession() {
        this.importFileName = undefined;
        this._setCarrierUploadFormAction();
    }

    async cancelFileImport() {
        try {
            const response = await this._carriers.cancelImport(this.importFileName);
            this._stopCheckingQueue();
        } catch (err) {
            this._notifier.error(`The request was not canceled. ${err}`);
            console.log(err);
        }
    }

    _displayPolicyStatus(status) {
        try {
            this._stopCheckingQueue();
            this.warningTypes = [];
            if (status.warnings && status.warnings.length) {
                status.warnings.forEach(w => w.codeValue = this._i18n.tr(`carrier-import-warning-${w.code}`));
                const warningTypeCodes = status.warnings.map(r => r.code).filter((value, index, self) => self.indexOf(value) === index);
                warningTypeCodes.forEach(wtc => this.warningTypes.push({ code: wtc, value: this._i18n.tr(`carrier-import-warning-${wtc}`) }));
            }
            if (status.correctiveActions && status.correctiveActions.length) {
                status.correctiveActions.forEach(ca => ca.action.typeValue = this._i18n.tr(ca.action.type));
            }

            this.policyStatus = status;

            if (this.policyStatus.policies && this.policyStatus.policies.length) {
                // Import completed with the policies, clear out the old corrective action data
                this.exportFileName = this.importFileName;
                this.closeUploadSession();
                this.ignoreCarrierWarnings = false;
                this.carrierImportAdditionalData = { statuses: [], targetPremiums: [], writingAgentChanges: [], ignores: [], splits: [] };
                this.carrierImportAdditionalDataValue = JSON.stringify(this.carrierImportAdditionalData);
                this.showReimportButton = false;
                return;
            }
            if (this.policyStatus.correctiveActions && this.policyStatus.correctiveActions.length) {
                this.policyStatus.correctiveActions.forEach(ca => {
                    if (ca.action.type === 'ca-manual-status-entry') {
                        const existing = this.carrierImportAdditionalData.statuses.find(x => x.policyNumber === ca.action.metadata.policyNumber);
                        if (existing) ca.alreadyCorrected = true; else ca.alreadyCorrected = false;
                    }
                    if (ca.action.type === 'ca-manual-target-premium-entry') {
                        const existing = this.carrierImportAdditionalData.targetPremiums.find(x => x.policyNumber === ca.action.metadata.policyNumber);
                        if (existing) ca.alreadyCorrected = true; else ca.alreadyCorrected = false;
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    addPolicyStatus(ca) {
        const model = Object.assign(
            ca.metadata,
            { isNew: true },
        );
        this._dialogService.open({ viewModel: EditCarrierPolicyStatus, model, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
            // Remove all the similar corrective actions
            this.policyStatus.correctiveActions.forEach(x => {
                if (x.alreadyCorrected) return;
                x.alreadyCorrected = x.action.type === ca.type && x.action.metadata.status === ca.metadata.status;
            });
        });
    }

    openPolicy(policyId) {
        this._ea.publish(c.EventKeys.site.openPolicy, { policyId: policyId });
    }

    showPolicyChanges(policy) {
        this._dialogService.open({ viewModel: PolicyChangelog, model: policy });
    }

    addProduct(ca) {
        const product = {
            carrier: { id: ca.metadata.carrierId, name: ca.metadata.carrierName },
            name: ca.metadata.product
        };
        this._dialogService.open({ viewModel: EditProduct, model: { product, products: [] } }).whenClosed(response => {
            if (response.wasCancelled) return;
            // Remove all the similar corrective actions
            this.policyStatus.correctiveActions.forEach(x => {
                if (x.alreadyCorrected) return;
                x.alreadyCorrected = x.action.type === ca.type && x.action.metadata.product === ca.metadata.product;
            });
        });
    }

    assignProductAlias(ca) {
        const product = {
            carrier: { id: ca.metadata.carrierId, name: ca.metadata.carrierName },
            productName: ca.metadata.product,
            policyNumber: ca.metadata.policyNumber
        };
        this._dialogService.open({ viewModel: AssignProductAlias, model: product}).whenClosed(response => {
            if (response.wasCancelled) return;
            // Remove all the similar corrective actions
            this.policyStatus.correctiveActions.forEach(x => {
                if (x.alreadyCorrected) return;
                x.alreadyCorrected = x.action.type === ca.type && x.action.metadata.product === ca.metadata.product;
            });
        });
    }

    selectPolicyStatus(ca) {
        this._dialogService.open({ viewModel: SelectPolicyStatus, model: ca.action.metadata }).whenClosed(response => {
            if (response.wasCancelled) return;
            const existing = this.carrierImportAdditionalData.statuses.findIndex(x => x.policyNumber === ca.action.metadata.policyNumber);
            if (existing >= 0) this.carrierImportAdditionalData.statuses.splice(existing, 1);
            this.carrierImportAdditionalData.statuses.push({ policyNumber: ca.action.metadata.policyNumber, status: response.output.status });
            this.carrierImportAdditionalDataValue = JSON.stringify(this.carrierImportAdditionalData);
            ca.alreadyCorrected = true;
        });
    }

    enterTargetPremium(ca) {
        this._dialogService.open({ viewModel: EnterTargetPremium, model: ca.action.metadata }).whenClosed(response => {
            if (response.wasCancelled) return;
            const existing = this.carrierImportAdditionalData.targetPremiums.findIndex(x => x.policyNumber === ca.action.metadata.policyNumber);
            if (existing >= 0) this.carrierImportAdditionalData.targetPremiums.splice(existing, 1);
            this.carrierImportAdditionalData.targetPremiums.push({ policyNumber: ca.action.metadata.policyNumber, targetPremium: response.output.targetPremium });
            this.carrierImportAdditionalDataValue = JSON.stringify(this.carrierImportAdditionalData);
            ca.alreadyCorrected = true;
        });
    }

    enterAgentNumber(ca) {
        this._dialogService.open({ viewModel: EnterAgentNumber, model: ca.action.metadata }).whenClosed(response => {
            if (response.wasCancelled) return;
            // Remove all the similar corrective actions for the same agent number
            this.policyStatus.correctiveActions.forEach(x => {
                if (x.alreadyCorrected) return;
                if (x.action.type !== ca.action.type) return;
                if (x.action.metadata.agentNumber !== ca.action.metadata.agentNumber) return;
                x.alreadyCorrected = true;
            });
        });
    }

    enterSplit(ca) {
        this._dialogService.open({ viewModel: EnterSplit, model: ca.action.metadata }).whenClosed(response => {
            if (response.wasCancelled) return;
            const existing = this.carrierImportAdditionalData.splits.findIndex(x => x.policyNumber === ca.action.metadata.policyNumber && x.row === ca.action.metadata.row);
            if (existing >= 0) this.carrierImportAdditionalData.splits.splice(existing, 1);
            this.carrierImportAdditionalData.splits.push({ policyNumber: ca.action.metadata.policyNumber, row: ca.action.metadata.row, split: response.output.split });
            this.carrierImportAdditionalDataValue = JSON.stringify(this.carrierImportAdditionalData);
            console.log(this.carrierImportAdditionalData);
            ca.alreadyCorrected = true;
        });
    }

    changeWritingAgent(ca) {
        this.carrierImportAdditionalData.writingAgentChanges.push({ policyNumber: ca.action.metadata.policyNumber, currentWritingAgentId: ca.action.metadata.currentWritingAgent.id });
        this.carrierImportAdditionalDataValue = JSON.stringify(this.carrierImportAdditionalData);
        ca.alreadyCorrected = true;
    }

    ignoreRow(ca) {
        this.carrierImportAdditionalData.ignores.push({ policyNumber: ca.action.metadata.policyNumber, row: ca.row, ignore: true });
        this.carrierImportAdditionalDataValue = JSON.stringify(this.carrierImportAdditionalData);
        ca.alreadyCorrected = true;
    }

    async exportStatusChanges() {
        const model = { format: 'commission', importFileName: this.exportFileName };
        await this._page.export(this._api, 'policy/export', model);
    }
}
