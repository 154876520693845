import { DialogController } from 'aurelia-dialog';
import { Members } from 'services/members';

export class EnterAgentNumber {
    static inject = [DialogController, Members];
    dialogController;
    _members;

    carrierId;
    carrierName;
    policyNumber;
    agentNumber;
    agent;

    constructor(dialogController, members) {
        this.dialogController = dialogController;
        this._members = members;
    }

    activate(model) {
        this.carrierId = model.carrierId;
        this.carrierName = model.carrierName;
        this.policyNumber = model.policyNumber;
        this.agentNumber = model.agentNumber;
    }

    async save() {
        try {
            if (!this.agent || !this.agentNumber) return;
            const response = await this._members.saveAgentNumber(this.agent.id, null, this.carrierId, this.agentNumber, null, true, null, null, null);
            console.log(response);

            this.dialogController.ok();
        } catch (err) {
            console.log(err);
        }
    }
} 

 