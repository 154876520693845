import { DialogController } from 'aurelia-dialog';
import { I18n } from 'common/i18n';
import { Content } from 'services/content';
import { Roles } from 'services/roles';
import { Notifier } from 'common/ui';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { c } from 'common/common';

export class SetEmailParameters {
    static inject = [DialogController, I18n, Content, Roles, Notifier, NewInstance.of(ValidationController)];
    dialogController;
    _i18n;
    _content;
    _roles;
    _notifier;
    _vc;

    singleEmailOptions = [
        { key: 'admin:email-type-agent', translation: '', value: 'Agent', type: 'Agent' },
        { key: 'admin:email-type-directupline', translation: '', value: 'DirectUpline', type: 'DirectUpline' },
        { key: 'admin:email-type-mentor', translation: '', value: 'Mentor', type: 'Mentor' },
        { key: 'admin:email-type-agency', translation: '', value: 'Agency', type: 'Agency' },
        { key: 'admin:email-type-other', translation: '', value: 'other', type: 'Email' },
    ];

    multipleEmailOptions = [
        { key: 'admin:email-type-agent', translation: '', value: 'Agent', type: 'Agent' },
        { key: 'admin:email-type-directupline', translation: '', value: 'DirectUpline', type: 'DirectUpline' },
        { key: 'admin:email-type-mentor', translation: '', value: 'Mentor', type: 'Mentor' },
        { key: 'admin:email-type-agency', translation: '', value: 'Agency', type: 'Agency' },
        { key: 'admin:email-type-entireupline', translation: '', value: 'EntireUpline', type: 'EntireUpline' },
        { key: 'admin:email-type-uplinetoagency', translation: '', value: 'UplineToAgency', type: 'UplineToAgency' },
        { key: 'admin:email-type-uplineagencies', translation: '', value: 'UplineAgencies', type: 'UplineAgencies' },
    ]

    emailFrom;
    emailTo;
    cc = [];
    bcc = [];
    ccTags = [];
    bccTags = [];

    constructor(dialogController, i18n, content, roles, notifier, validationController) {
        this.dialogController = dialogController;
        this._i18n = i18n;
        this._content = content;
        this._roles = roles;
        this._notifier = notifier;
        this._vc = validationController;
        this._vc.addRenderer(new BootstrapFormValidationRenderer());

        ValidationRules
            .ensure('emailFrom').required().email()
            .ensure('emailToEmail').required().when(m => m.emailTo === 'other')
            .on(this);
    }

    async activate(model) {
        try {
            this.uiWidgetKey = model.uiWidgetKey;
            this._emailParameters = model.emailParameters;
            await this._load();
            this._initialize();
            this._vc.reset();
        } catch (err) {
            console.log(err);
        }
    }

    async _load() {
        try {
            this.singleEmailOptions.forEach(eo => {
                eo.translation = this._i18n.tr(eo.key);
            });
            this.roles = await this._roles.all();
            this.roles.forEach(r => {
                this.multipleEmailOptions.push({ key: r, translation: `ROLE: ${this._i18n.tr(`roles-name-${r}`)}`, value: r, type: 'Role' });
            });
            this.multipleEmailTags = [];
            this.multipleEmailOptions.forEach(eo => {
                if (!eo.translation) eo.translation = this._i18n.tr(eo.key);
                this.multipleEmailTags.push(eo.translation);
            });
        } catch (err) {
            console.log(err);
        }
    }

    _initialize() {
        try {
            this.emailFrom = this._emailParameters.from.email;
            let emailTo = this._emailParameters.to ? this.singleEmailOptions.find(x => x.value === this._emailParameters.to.type) : undefined;
            let isToTaskAssignee = false;
            if (this._emailParameters.to) {
                isToTaskAssignee = this._emailParameters.to.type === 'TaskAssignee';
            }
            this.canEditTo = !isToTaskAssignee;
            if (!this.canEditTo) {
                this.emailTo = 'TaskAssignee';
            } else if (emailTo) {
                this.emailTo = emailTo.value;
            } else if (!this._emailParameters.to) {
                // select the other option
                this.emailTo = 'other';
            } else {
                this.emailTo = this._emailParameters.to.email;
            }

            this.cc = this._emailParameters.cc ?? [];
            if (this._emailParameters.cc && this._emailParameters.cc.length) {
                this._emailParameters.cc.forEach(cc => {
                    let eo = this.singleEmailOptions.find(x => x.type === cc.type);
                    if (!eo) eo = this.multipleEmailOptions.find(x => x.type === cc.type);
                    if (cc.type === 'Email') {
                        this.ccTags.push(cc.email);
                    } else if (cc.type === 'Agent' || cc.type === 'Agency' || cc.type === 'DirectUpline' || cc.type === 'Mentor' || cc.type === 'EntireUpline' || cc.type === 'TaskAssignee' || cc.type === 'UplineToAgency') {
                        this.ccTags.push(eo?.translation ?? cc.type);
                    } else if (cc.type === 'Role') {
                        this.ccTags.push(cc.email);
                    }
                });
            }
            this.bcc = this._emailParameters.bcc ?? [];
            if (this._emailParameters.bcc && this._emailParameters.bcc.length) {
                this._emailParameters.bcc.forEach(bcc => {
                    let eo = this.singleEmailOptions.find(x => x.type === cc.type);
                    if (!eo) eo = this.multipleEmailOptions.find(x => x.type === cc.type);
                    if (bcc.type === 'Email') {
                        this.bccTags.push(bcc.email);
                    } else if (bcc.type === 'Agent' || bcc.type === 'Agency' || bcc.type === 'DirectUpline' || bcc.type === 'Mentor' || bcc.type === 'EntireUpline' || bcc.type === 'TaskAssignee' || bcc.type === 'UplineToAgency') {
                        this.bccTags.push(eo?.translation ?? bcc.type);
                    } else if (bcc.type === 'Role') {
                        this.bccTags.push(bcc.email);
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    ccEmailChanged(emails) {
        this.cc = this._validateEmails(emails);
    }

    bccEmailChanged(emails) {
        this.bcc = this._validateEmails(emails, true);
    }

    _validateEmails(emails, bcc = false) {
        const emailParameters = [];
        emails.forEach(e => {
            let inList = this.multipleEmailOptions.find(x => x.translation.toLowerCase() === e.toLowerCase());
            if (!inList) inList = this.multipleEmailOptions.find(x => x.type.toLowerCase() === e.toLowerCase());
            if (inList) {
                const emailParameter = { type: inList.type, bcc };
                emailParameter.email = emailParameter.type === 'Role' ? inList.value : '';
                emailParameters.push(emailParameter);
                return;
            }
            emailParameters.push({ type: 'Email', email: e, bcc });
            // See if it is a valid email address
            if (!c.RegEx.email.test(e)) {
                this._notifier.info('email-address-may-be-invalid', undefined, false, { email: e });
            }
        });
        return emailParameters;
    }

    async save() {
        try {
            const v = await this._vc.validate();
            if (!v.valid) return;
            const settings = {
                from: { type: 'Email', bcc: false, email: this.emailFrom },
                to: { bcc: false },
                cc: this.cc,
                bcc: this.bcc,
            };
            if (this.emailTo === 'other') {
                settings.to.type = 'Email';
                settings.to.email = this.emailToEmail;
            } else {
                settings.to.type = this.emailTo;
                settings.to.email = '';
            }
            await this._content.saveEmailSettings(this.uiWidgetKey, settings);
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
        }
    }
} 

 