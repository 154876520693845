import { DialogController } from 'aurelia-dialog';
import { ProductionService } from 'services/production-service';

export class SelectPolicyStatus {
    static inject = [DialogController, ProductionService];
    dialogController;
    _productionService;

    lpfnStatuses;
    lpfnStatus;

    constructor(dialogController, productionService) {
        this.dialogController = dialogController;
        this._productionService = productionService;
    }

    activate(model) {
        this.carrierName = model.carrierName;
        this.policyNumber = model.policyNumber;
        this.additionalData = model.additionalData;
        this.lpfnStatus = undefined;
        this._load();
    }

    async _load() {
        try {
            this.lpfnStatuses = await this._productionService.policyStatuses();
            this.lpfnStatus = this.lpfnStatuses[0];
        } catch (err) {
            console.log(err);
        }
    }
    
    async save() {
        this.dialogController.ok({ status: this.lpfnStatus });
    }
} 

 